import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
import { mapGetters, mapActions } from "vuex";
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";

const PlaybackUtilities = {
  computed: {
    ...mapGetters(["appConfig", "profileid"]),
  },

  methods: {
    ...mapActions(["authorizeCurrentStreamApi", "setCurrentStreamApi"]),
    // showPlayer() {
    //   this.playerInstance.blowUpPlayer();

    //   this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
    //   this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);
    //   this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

    //   this.playbackInitiated = true;
    //   eventBus.$emit("player-visibility", true);
    // },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },

    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentDetails(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          console.log("THE CONTENT ITEM RESPONSE ->", episodeList[episodeIndex]);

          if (typeof resp == "object" && !resp.data.errorcode) {
            episodeList[episodeIndex].title = resp.data.title;
            episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
            episodeList[episodeIndex].seriesname = resp.data.seriesname;
            episodeList[episodeIndex].poster = resp.data.poster;
            let nextepisodeid = episodeList[episodeIndex + 1] ? episodeList[episodeIndex + 1].objectId : "";
            localStorage.setItem("nextepisodeid", nextepisodeid);
          } else if (typeof resp == "boolean" && resp == true) {
            console.log("Content detail present");
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }

          let isPlaybackAllowed = this.checkAvailabilityAllowsPlayback(episodeList[episodeIndex]);

          if (!isPlaybackAllowed) throw isPlaybackAllowed;

          this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired)
            .then((resp) => {
              let loadPlayer = false;

              if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
                // Call the Stream Authorize API
                this.authorizeCurrentStreamApi()
                  .then((authResponse) => {
                    console.log("authResponse - From Authorize API --- > ", authResponse);

                    if (authResponse.data.success) {
                      if (typeof resp == "object" && !resp.data.errorcode) {
                        this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                        loadPlayer = true;
                      } else if (typeof resp == "boolean" && resp == true) {
                        loadPlayer = true;
                      }

                      if (loadPlayer == true) {
                        this.loadContentMetaData(episodeList[episodeIndex], null);

                        if (typeof resp == "object" || totalCount == 1) {
                          if (isFirstTime == true) {
                            let playlist = this.buildPlayerPlaylist(episodeList, 0);
                            this.playerInstance.loadPlaylist(playlist, totalCount);
                          } else {
                            let playlist = this.playerInstance.playlist;
                            let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                            playlist.updateItem(episodeIndex, updatedContentItem);
                          }
                        }

                        this.playerInstance.loadContent(episodeIndex).then(() => {
                          this.playerInstance.play().then(() => {
                            console.log("Playback successful");

                            const payload = {
                              contentId: episodeList[episodeIndex].objectid,
                              params: {
                                devicetype: "PC",
                              },
                            };

                            this.setCurrentStreamApi(payload).then((setStreamResponse) => {
                              console.log("THE SET STREAM API RESP === > ", setStreamResponse);
                            });
                          });
                        });
                      } else {
                        let playbackError = true;

                        if (typeof resp == "object") {
                          playbackError = resp.data;
                        }

                        if(resp.data.errorcode === 9902) {
                          this.hidePlayer();
  
                          eventBus.$emit("showAuthorizeErrorMessage", {
                            state: true,
                            messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                          });
                        }


                        throw playbackError;
                      }
                    } else {
                      let playbackError = true;
                      console.log("THE PLAYBCK ERRORR ___ AUTHORIZE --- ", resp);
                      if (typeof resp == "object") {
                        playbackError = resp.data;
                      }

                      this.hidePlayer();

                      eventBus.$emit("showAuthorizeErrorMessage", {
                        state: true,
                        messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                      });

                      throw playbackError;
                    }
                  })
                  .catch((err) => {
                    let playbackError = true;
                    console.log("THE PLAYBCK ERRORR ___ AUTHORIZE --- ", resp);
                    if (typeof resp == "object") {
                      playbackError = resp.data;
                    }

                    this.hidePlayer();

                    eventBus.$emit("showAuthorizeErrorMessage", {
                      state: true,
                      messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                    });

                    throw playbackError;
                  });
              }
              // If the concurrency APIs are disabled
              else {
                if (typeof resp == "object" && !resp.data.errorcode) {
                  this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                  loadPlayer = true;
                } else if (typeof resp == "boolean" && resp == true) {
                  loadPlayer = true;
                }

                if (loadPlayer == true) {
                  this.loadContentMetaData(episodeList[episodeIndex], null);

                  if (typeof resp == "object" || totalCount == 1) {
                    if (isFirstTime == true) {
                      let playlist = this.buildPlayerPlaylist(episodeList, 0);
                      this.playerInstance.loadPlaylist(playlist, totalCount);
                    } else {
                      let playlist = this.playerInstance.playlist;
                      let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                      playlist.updateItem(episodeIndex, updatedContentItem);
                    }
                  }

                  this.playerInstance.loadContent(episodeIndex).then(() => {
                    this.playerInstance.play().then(() => {
                      console.log("Playback successful");
                    });
                  });
                } else {
                  let playbackError = true;

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }

                  this.hidePlayer();

                  // eventBus.$emit("showAuthorizeErrorMessage", {
                  //   state: true,
                  //   messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  // });

                  throw playbackError;
                }
              }
            })
            .catch((error) => {
              console.log("Internal error : ", error);
            });
        })
        .catch((error) => {
          console.log("Content playback failed : ", error);
          this.hidePlayer();
        });
    },

    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      let nextepisodeid = this.episodeList[endedContentIndex + 1]
        ? this.episodeList[endedContentIndex + 1].objectid
        : "";
      localStorage.setItem("nextepisodeid", nextepisodeid);
      if (episodeIndex < this.totalEpisodeCount) {
        this.getNextEpisodeList(episodeIndex, this.episodeList, this.isMpegRequired)
          .then((resp) => {
            if (typeof resp == "object" && !resp.data.errorcode) {
              let episodeList = resp.data.data;
              this.episodeList = this.episodeList.concat(episodeList);

              let deltaPlaylist = this.buildPlayerPlaylist(this.episodeList, episodeIndex);

              this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
            } else if (typeof resp == "boolean" && resp == true) {
              console.log("Episode in content list");
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }
              throw playbackError;
            }
            this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
          })
          .catch((error) => {
            console.log("Content playback failed : ", error);
            this.hidePlayer();
          });
      } else {
        this.hidePlayer();
      }
    },
    loadChosenContentData(chosenContentIndex) {
      console.log("Load chosen index in category card : ", chosenContentIndex);
      let episodeIndex = chosenContentIndex;
      this.playerInstance.unloadPlayer();

      this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, false);
    },
    loadMoreContentData() {
      let episodeIndex = this.episodeList.length;
      this.fetchEpisodes(this.episodeList[episodeIndex - 1]).then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;
          this.episodeList = this.episodeList.concat(episodeList);

          let deltaPlaylist = this.buildPlayerPlaylist(this.episodeList, episodeIndex);

          this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
        }
      });
    },

    buildPlayerPlaylist(episodeList, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(
          episodeList[aIndex],
          "watchedduration" in episodeList[aIndex] ? episodeList[aIndex].watchedduration : 0
        );

        playlist.push(playlistItem);
      }

      return playlist;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },

    createPlayerPlaylistItem(content, playbackPosition) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
				position: playbackPosition,
				scrubbing: content.scrubbing ? content.scrubbing[0] : '',
      });

      // console.log("THIS PLAYLIST ITEM -->", playlistItem);

      return playlistItem;
    },

    fetchContentDetails(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.contentdetails != undefined) {
          console.log("THE CONTENT ITEM ->", contentItem);
          resolve(true);
        } else {
          let requestData = {};
          let queryParams = {};

          queryParams.displaylanguage = this.localDisplayLang;

          if (isMpegRequired == true) {
            queryParams.mpegtspackage = "YES";
          }

          requestData.contentid = contentItem.objectid;

          requestData.params = queryParams;

          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },

    handleFetchContentStreamUrl(response, contentItem) {
			contentItem.streamUrl = response.data.streamfilename;
			contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';
    },

    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload;

      payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      return payload;
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },

    fetchEpisodes(content) {
      return this.sendContentListRequest(content, this.isMpegRequired);
    },

    getNextEpisodeList(episodeIndex, episodeList, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (episodeIndex < episodeList.length) {
          resolve(true);
        } else {
          resolve(this.sendContentListRequest(episodeList[episodeIndex - 1], isMpegRequired));
        }
      });
    },

    sendContentListRequest(content, isMpegRequired) {
      let pageSize = 15;
      let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: content.seriesid,
        seasonnum: content.seasonnum,
        page: pageNum,
        displaylanguage: this.localDisplayLang,
        pagesize: pageSize,
      };

      if (isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }
      return store.dispatch("listContent", payload);
    },

    checkEpisodeInList(objectId, episodeList) {
      let isEpisodeInList = false;

      if (episodeList.findIndex((element) => element.objectid == objectId) >= 0) {
        isEpisodeInList = true;
      }

      return isEpisodeInList;
    },
    getEpisodeIndexInList(objectId, episodeList) {
      let episodeIndex;

      episodeIndex = episodeList.findIndex((element) => element.objectid == objectId);

      return episodeIndex;
    },
    fetchEpisodeRecursively(fetchEpisodeFn, content, checkEpisodeStatusFn, isDone = false) {
      if (isDone) return true;
      let fetchPromise = fetchEpisodeFn(content);
      return fetchPromise.then((response) => {
        if (!response.data.errorcode) {
          let episodeList = response.data.data;

          if ("watchedduration" in content) {
            episodeList = this.replaceTheObjectForContinueWatch(episodeList, content);
          }

          this.totalEpisodeCount = response.data.totalcount;
          this.episodeList = this.episodeList.concat(episodeList);
          return this.fetchEpisodeRecursively(
            fetchEpisodeFn,
            content,
            checkEpisodeStatusFn,
            checkEpisodeStatusFn(content.objectid, this.episodeList)
          );
        } else {
          return true;
        }
      });
    },
    checkAvailabilityAllowsPlayback(contentItem) {
      let { availabilityList, packageList } = this.determineContentPlanDetails(contentItem, this.availabilities);
      let isPlaybackAllowed = false;

      availabilityList.every((availability) => {
        if (availability.pricemodel === "PLAN") {
          if (this.subscriberid && this.subscriptions.length > 0) {
            console.log("THE AVAILABLITY SUB ID -- ", this.subscriberid, this.subscriptions);

            this.subscriptions.every((subscription) => {
              if (this.getDateForPlayback(subscription.nextbilling) >= this.getTodaysDate()) {
                if (subscription.availabilityset.includes(availability.availabilityid)) {
                  contentItem.isDrmContent = true;
                  contentItem.videoType = "Content";

                  contentItem.playback_details = {
                    packageid: packageList[0].packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageList[0].drmscheme[0],
                  };

                  isPlaybackAllowed = true;
                  return false;
                }
              }
              return true;
            });
          }

          // For Sub profiles
          else if (this.subscriberid && this.subscriptions.length === 0 && this.subscriberid !== this.profileid) {
            eventBus.$emit("showAuthorizeErrorMessage", {
              state: true,
              messageData: {
                title: "Error",
                message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
              },
            });

            return false;
          }
          // For Master Profile
          else if (this.subscriberid && this.subscriptions.length === 0) {
            console.log("THE REDIRECT POP up --- >");

            let payload = { listType: "onlySubscribe" };
            eventBus.$emit("subscribePopup", payload);

            return false;
          }
        } else if (availability.pricemodel == "FREE") {
          contentItem.isDrmContent = true;
          contentItem.videoType = "Content";

          contentItem.playback_details = {
            packageid: packageList[0].packageid,
            availabilityid: availability.availabilityid,
            drmscheme: packageList[0].drmscheme[0],
          };

          isPlaybackAllowed = true;
          return false;
        }

        if (isPlaybackAllowed == true) {
          return false;
        } else {
          return true;
        }
      });

      console.log("Returning playback check : ", isPlaybackAllowed);
      return isPlaybackAllowed;
    },

    determineContentPlanDetails(contentItem, availabilityList) {
      let contentAvailabilityList = contentItem.contentdetails;
      let filteredAvailabilityList = [];
      let filteredPackageList = [];

      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      availabilityList.every((availability) => {
        contentAvailabilityList.every((element) => {
          let isPackageFound = false;
          if (element.availabilityset.includes(availability.availabilityid)) {
            if (!isAndroid && hasNativeHlsSupport != "") {
              if (element.drmscheme[0] == "FAIRPLAY") {
                filteredAvailabilityList.push(availability);
                filteredPackageList.push(element);
                isPackageFound = true;
              }
            } else if (element.drmscheme[0] == "WIDEVINE") {
              filteredAvailabilityList.push(availability);
              filteredPackageList.push(element);
              isPackageFound = true;
            }
          }
          if (isPackageFound) {
            return false;
          } else {
            return true;
          }
        });
        return true;
      });

      return { availabilityList: filteredAvailabilityList, packageList: filteredPackageList };
    },

    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },

    replaceTheObjectForContinueWatch(episodeList, content) {
      let list = episodeList;

      list.forEach((element, index) => {
        if (element.objectid == content.objectid) {
          let newObjectForTheEpisode = { ...content, ...element };

          if (index > -1) {
            list[index] = newObjectForTheEpisode;
          }
        }
      });

      return list;
    },
  },
};

export default PlaybackUtilities;
